<template>
    <div id="fiche-client-page" class="pt-4 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Consignes
        </div>
        <div class="form-group mt-5">
            <Consigne v-for="consigne in profiles" :consigne="consigne" :key="consigne.idactivity_profile"/>
        </div>
    </div>
</template>

<script>
import router from '../../../router'
import Consigne from "@/components/Pages/Consignes/Consigne";

export default {
    name: "Consignes",
    components: {Consigne},
    props: {
        profiles: {
            type: Array
        }
    },
    mounted() {
        this.$emit('menu', 'clients')
        if (!this.profiles) {
            router.push({name: 'fiche-client', params: {clientId: router.currentRoute.params.clientId}})
        }
    },
    methods: {
        getBack() {
            router.go(-1)
        },
    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus, button.headercontrat:focus {
    box-shadow: none !important;
    color: black;
}
</style>
