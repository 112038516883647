<template>
    <div class="row">
        <div class="col-12 p-0 m-0">
            <h2 class="bg-dark text-center text-white p-1">{{ consigne.label }}</h2>
            <div class="px-2">
                <div class="card bg-white mb-2" v-for="category in categories"
                     :key="category.idprofile_model_questions">
                    <h4 class="text-center p-2 bg-light">{{ category.question }}</h4>
                    <div class="d-flex mx-2 justify-content-start align-items-start custom-form-control"
                         v-for="response in category.responses"
                         :key="response.idprofile_model_questions">
                        <div class="w-100 p-1 mb-1" style="border: solid 1px #ececec">
                            <div v-html="formatResponse(response)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Consigne",
    data() {
        return {
            categories: []
        }
    },
    props: {
        consigne: {
            required: true,
            type: Object
        }
    },
    mounted() {
        this.categories = this.consigne.profile_model?.questions?.filter(i => !i.group_by_id) ?? []
        this.categories.forEach(category => {
            category.responses = this.consigne.responses.filter(i => i.group_by_id === category.profile_model_questions_idprofile_model_questions);
            category.responses.forEach(i => {
                i.question = this.consigne.profile_model.questions.find(j => j.idprofile_model_questions === i.profile_model_questions_idprofile_model_questions)
            })
        })
    },
    methods: {
        formatResponse(response) {
            const question = `<strong class="font-weight-bold mr-1 text-nowrap">${response.questions}:</strong>`
            let value = response.response_value;

            if (response.response_value.includes('#')) {
                return `${question} ${response.response_value.split('#').map(i => `<span style="margin-bottom: 5px">${i}</span>`).join(', ')}`
            } else if (response.question.profile_questions_type_id === 5) {
                value = this.$moment(response.response_value).format('DD/MM/YYYY');
            } else if (response.question.profile_questions_type_id === 10) {
                return `${question} <input style="pointer-events: none;" type="checkbox" ${response.response_value === 'on' ? 'checked="checked"' : ''}>`
            }

            return `<div>${question} ${value}</div>`

        }
    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus, button.headercontrat:focus {
    box-shadow: none !important;
    color: black;
}
</style>
